import { defineStore } from "pinia";

export const useReaStore = defineStore("rea", {
  state: () => {
    return { 
      hasData: false,
      data: {}
     };
  },
  actions: {
    setData(rea) {
      this.data = rea;
      this.hasData = true;
    },
    reset() {
      this.data = {};
      this.hasData = false;
    }
  }
});