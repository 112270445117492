import { createI18n } from 'vue-i18n'

const i18n = createI18n({
  // default locale
  locale: 'pt-br',

  // translations
  messages: {
    'pt-br': {
      //NAVBAR and SEARCH page
      appTitle: 'aquare!a',
      slogan: 'Os 5R\'s em um só lugar.',
      navbar: {
        ea: 'Educação Aberta',
        contato: 'Contato',
        sobre: 'Sobre',
        nr: 'Novo recurso',
        user: {
            nr: 'Novo recurso...',
            mr: 'Meus REA...',
            config: 'Configurações',
            perfil: 'Perfil',
            sair: 'Sair'
        },
        entrar: 'Entrar'
      },
      busca: 'Nenhum REA foi encontrado.',
      //LOGIN
      email: 'Digite seu e-mail',
      senha: 'Digite sua senha',
      lembrarSenha: 'Esqueceu a senha?',
      lembrarSenhaClique: 'Clique aqui.',
      cadastro: 'Ainda não tem cadastro?',
      cadastroNovo: 'Crie sua conta.',
      aguardar: 'Por favor, aguarde...',
      emailSenhaAlerta: 'E-mail ou senha não cadastrados ou não ativados.',
      emailRedefinicao: 'Por favor, digite o e-mail para redefinir a senha.',
      emailAlerta: 'E-mail não cadastrado ou não ativado.',
      novaSenha: {
        titulo: 'Nova senha',
        mensagemOk: 'Uma nova senha foi enviada para seu e-mail.',
        mensagemErro: 'Infelizmente não foi possível redefinir sua senha. Por favor, entre em contato para que possamos auxiliar.'
      },
      //COLABORADOR
      colaborador: {
        cabecalho: 'Colaborador',
        nome: 'Nome *',
        email: 'E-mail *',
        sobre: 'Sobre você *',
        nomeIE: 'Nome da Instituição de Ensino (I.E.) *',
        siglaIE: 'Sigla da I.E. *',
        rede: 'Rede de Ensino *',
        redes: {
          municipal: 'Municipal',
          estadual: 'Estadual',
          federal: 'Federal',
          privada: 'Privada'
        },
        cidade: 'Cidade *',
        uf: 'UF *',
        funcao: 'Função *',
        funcoes: {
          professor: 'Professor(a)',
          coordenador: 'Coordenador(a)',
          diretor: 'Diretor(a)',
          estudante: 'Estudante',
          outra: 'Outra'
        },
        senha: 'Senha *',
        senhaConfirmacao: 'Confirmação de senha *',
        areas: 'Área(s) de atuação/interesse *',
        efl: 'EF - Linguagens',
        efm: 'EF - Matemática',
        efcn: 'EF - Ciências da Natureza',
        efch: 'EF - Ciências Humanas',
        efer: 'EF - Ensino Religioso',
        emlt: 'EM - Linguagens e suas Tecnologias',
        emmt: 'EM - Matemática e suas Tecnologias',
        emcnt: 'EM - Ciências da Natureza e suas Tecnologias',
        emchsa: 'EM - Ciências Humanas e Sociais Aplicadas',
        mv: 'Multidisciplinar',
        validacao: {
          nome: 'Por favor, informe o nome.',
          email: 'Por favor, informe um e-mail válido.',
          sobre: 'Por favor, descreva-se para que outras pessoas possam conhecê-la/o.',
          nomeIE: 'Por favor, informe o nome da I.E. em que você trabalha ou estuda.',
          siglaIE: 'Por favor, informe a sigla do nome da I.E. em que você trabalha ou estuda.',
          rede: 'Por favor, informe a Rede de Ensino da I.E.',
          cidade: 'Por favor, informe a cidade de localização da I.E.',
          uf: 'Por favor, informe a UF da cidade informada.',
          funcao: 'Por favor, informe a sua função na I.E.',
          senha: 'Por favor, informe a senha para acesso ao sistema.',
          senhaConfirmacao: 'Por favor, confirme a senha para acesso ao sistema.',
          senhaNaoConfere: 'As senhas não conferem. Por favor, digite-as novamente.',
          areas: 'Por favor, indique pelo menos uma área de atuação/interesse.',
        }
      },
      //NOVO REA
      documento: {
        botao: 'Criar conteúdo',
        cabecalho: 'Conteúdo'
      },
      trilha: {
        botao: 'Criar trilha de aprendizagem',
        cabecalho: 'Trilha de aprendizagem'
      },
      upload: {
        botao: 'Upload de arquivo',
        cabecalho: 'Arquivo'
      },
      salvar: {
        titulo: 'Aviso',
        aviso1: 'O REA em edição precisa ser salvo para que os dados não sejam perdidos!',
        aviso2: 'Por favor, salve pelo menos o rascunho ou cancele a edição.',
      },
      botao: {
        cancelar: 'Cancelar',
        avancar: 'Avançar',
        voltar: 'Voltar',
        rascunho: 'Salvar rascunho',
        publicar: 'Publicar',
        avaliacoes: 'Avaliações',
        adaptar: 'Adaptar',
        guardar: 'Guardar',
        imprimir: 'Imprimir',
        adicionar: 'Adicionar',
        excluir: 'Excluir',
        continuar: 'Continuar edição',
        enviar: 'Enviar',
        salvar: 'Salvar'
      },
      //METADATA
      meta: {
        titulo: 'Título *',
        descricao: 'Descrição *',
        autores: 'Autor(es) *',
        tipo: 'Tipo de conteúdo *',
        tipos: {
          html: 'Documento HTML',
          trilha: 'Trilha de aprendizagem',
          video: 'Vídeo',
          audio: 'Arquivo de áudio',
          pdf: 'Arquivo PDF',
          texto: 'Arquivo de texto',
          software: 'Software',
          outro: 'Outro'
        },
        area: 'Área do conhecimento *',
        areas: {
          ei1: 'EI - O eu, o outro e o nós',
          ei2: 'EI - Corpo, gestos e movimentos',
          ei3: 'EI - Traços, sons, cores e formas',
          ei4: 'EI - Escuta, fala, pensamento e imaginação',
          ei5: 'EI - Espaços, tempos, quantidades, relações e transformações',
          efl: 'EF - Linguagens',
          efm: 'EF - Matemática',
          efcn: 'EF - Ciências da Natureza',
          efch: 'EF - Ciências Humanas',
          efer: 'EF - Ensino Religioso',
          emlt: 'EM - Linguagens e suas Tecnologias',
          emmt: 'EM - Matemática e suas Tecnologias',
          emcnt: 'EM - Ciências da Natureza e suas Tecnologias',
          emchsa: 'EM - Ciências Humanas e Sociais Aplicadas',
          mv: 'Multidisciplinar'
        },
        idioma: 'Idioma *',
        idiomas: {
          p: 'Português',
          i: 'Inglês',
          e: 'Espanhol',
          o: 'Outro'
        },
        tags: 'Palavras-chave *',
        cc: 'Tipo de licença Creative Commons *',
        comercial: {
          pergunta: 'Permitir usos comerciais da sua obra?',
          s: 'Sim',
          n: 'Não'
        },
        modificacao: {
          pergunta: 'Permitir modificações da sua obra?',
          s: 'Sim',
          i: 'Sim, desde que outros compartilhem igual'
        },
        validacao: {
          titulo: 'Por favor, informe o título do REA.',
          descricao: 'Por favor, descreva o REA.',
          autores: 'Por favor, informe o nome de pelo menos um autor do REA.',
          tipo: 'Por favor, informe o tipo do REA.',
          area: 'Por favor, informe a área de conhecimento a que pertence o REA.',
          idioma: 'Por favor, informe o idioma do REA.',
          tags: 'Por favor, informe pelo menos uma palavra-chave.',
          comercial: 'Por favor, decida sobre o uso comercial do REA.',
          modificacao: 'Por favor, decida sobre modificações do REA.',
          editor: 'Por favor, é preciso digitar algum conteúdo para o REA.'
        }
      },
      //REVISÃO + DISPLAY
      revisao: {
        capa: 'Definir imagem de capa',
        autores: 'Autor(es)',
        tipo: 'Tipo de conteúdo',
        area: 'Área do conhecimento',
        idioma: 'Idioma',
        data: 'Data de publicação',
        tags: 'Palavras-chave',
        licenca: 'Licença',
        arquivo: 'Nome do arquivo',
        reaurl: 'Link para compartilhar este REA',
        reaurlclick: 'Clique para copiar endereço do link'
      },
      //AVALIAÇÃO
      avaliacao: {
        pergunta: 'O que você achou deste recurso?',
        nota: 'Sua avaliação',
        comentario: 'Escreva o seu comentário aqui'
      },
      //CAIXAS DE DIÁLOGO
      sucesso: {
        titulo: 'Parabéns!',
        mensagem: 'Você publicou um REA e acaba de contribuir com uma Educação de qualidade!',
        titulodraft: 'Muito bem!',
        mensagemdraft: 'Você acabou de salvar o rascunho de um REA e poderá voltar a editá-lo a qualquer momento!',
        titulocolaborador: 'Seja bem-vinda/o!',
        mensagememail: 'Por favor, acesse seu e-mail para ativar o seu cadastro. Obrigado!',
        tituloatualizacao: 'Atualização de perfil',
        mensagematualizacao: 'Os seus dados foram atualizados!'
      },
      download: {
        titulo: 'Progresso de download',
        progresso: 'Por favor, aguarde o download do arquivo. Enquanto isso, não atualize e nem feche esta página.'
      },
      aviso: {
        titulo: 'Aviso'
      },
      social: {
        facebook: 'Compartilhar no Facebook',
        linkedin: 'Compartilhar no LinkedIn',
        gmail: 'Compartilhar pelo Gmail',
        whatsapp: 'Compartilhar pelo WhatsApp',
        Telegram: 'Compartilhar pelo Telegram',
        hashtag: '#RecursoEducacionalAberto',
        message: 'Olá! Confira este recurso educacional aberto: \n'
      }
    },
    'en-us': {
      //NAVBAR and SEARCH page
      appTitle: 'aquare!a',
      slogan: 'The 5R\'s in just one place.',
      navbar: {
        ea: 'Open Education',
        contato: 'Contact Us',
        sobre: 'About',
        nr: 'New resource',
        user: {
            nr: 'New resource...',
            mr: 'My OER...',
            config: 'Settings',
            perfil: 'Profile',
            sair: 'Logout'
        },
        entrar: 'Login'
      },
      busca: 'No OER was found.',
      //LOGIN
      email: 'Enter your e-mail',
      senha: 'Enter your password',
      lembrarSenha: 'Forgot your password?',
      lembrarSenhaClique: 'Click here.',
      cadastro: 'Not yet registered?',
      cadastroNovo: 'Create your account.',
      aguardar: 'Please, wait...',
      emailSenhaAlerta: 'E-mail or password not registered or not activated.',
      emailRedefinicao: 'Please, enter your e-mail to reset password.',
      emailAlerta: 'E-mail not registered or not activate.',
      novaSenha: {
        titulo: 'Reset password',
        mensagemOk: 'A new password was sent to your e-mail.',
        mensagemErro: 'Unfortunately was not possible to reset your password. Please, contact us so we can help you.'
      },
      //COLABORADOR
      colaborador: {
        cabecalho: 'Collaborator',
        nome: 'Name *',
        email: 'E-mail *',
        sobre: 'About you *',
        nomeIE: 'Educational Institution (E.I.) name *',
        siglaIE: 'E.I. acronym *',
        rede: 'School system *',
        redes: {
          municipal: 'Municipal',
          estadual: 'State',
          federal: 'Federal',
          privada: 'Private'
        },
        cidade: 'City *',
        uf: 'Federativa Unit *',
        funcao: 'Role *',
        funcoes: {
          professor: 'Teacher',
          coordenador: 'Academic Coordinator',
          diretor: 'Principal',
          estudante: 'Student',
          outra: 'Other'
        },
        senha: 'Password *',
        senhaConfirmacao: 'Confirm password *',
        areas: 'Area of expertise/interest *',
        efl: 'PE - Language and Visual Arts',
        efm: 'PE - Mathematics',
        efcn: 'PE - Science',
        efch: 'PE - Humanities',
        efer: 'PE - Religious Education',
        emlt: 'SE - Languages and their Technologies',
        emmt: 'SE - Mathematics and their Technologies',
        emcnt: 'SE - Science and its Technologies',
        emchsa: 'SE - Humanities and Social Sciences',
        mv: 'Multidisciplinary'       
      },
      //NOVO REA
      documento: {
        botao: 'Create a new content',
        cabecalho: 'Content'
      },
      trilha: {
        botao: 'Create a new learning path',
        cabecalho: 'Learning path'
      },
      upload: {
        botao: 'File upload',
        cabecalho: 'File'
      },
      salvar: {
        titulo: 'Warning',
        aviso1: 'The OER being edited needs to be saved so that the data is not lost!',
        aviso2: 'Please, save it at least as a draft or cancel editing.'
      },
      botao: {
        cancelar: 'Cancel',
        avancar: 'Next',
        voltar: 'Back',
        rascunho: 'Save draft',
        publicar: 'Publish',
        avaliacoes: 'Reviews',
        adaptar: 'Revise',
        guardar: 'Retain',
        imprimir: 'Print',
        adicionar: 'Add',
        excluir: 'Delete',
        continuar: 'Continue editing',
        enviar: 'Send',
        salvar: 'Save'
      },
      //METADATA
      meta: {
        titulo: 'Title *',
        descricao: 'Description *',
        autores: 'Author(s) *',
        tipo: 'Content type *',
        tipos: {
          html: 'HTML document',
          trilha: 'Learning path',
          video: 'Video',
          audio: 'Audio file',
          pdf: 'PDF file',
          texto: 'Text file',
          software: 'Software',
          outro: 'Other'
        },
        area: 'Area of knowledge *',
        areas: {
          ei1: 'PS - The I, the other and the we',
          ei2: 'PS - Body, gestures and movements',
          ei3: 'PS - Lines, sounds, colors and shapes',
          ei4: 'PS - Listening, speaking, thinking and imagination',
          ei5: 'PS - Spaces, times, quantities, relationships and transformations',
          efl: 'PE - Language and Visual Arts',
          efm: 'PE - Mathematics',
          efcn: 'PE - Science',
          efch: 'PE - Humanities',
          efer: 'PE - Religious Education',
          emlt: 'SE - Languages and their Technologies',
          emmt: 'SE - Mathematics and their Technologies',
          emcnt: 'SE - Science and its Technologies',
          emchsa: 'SE - Humanities and Social Sciences',
          mv: 'Multidisciplinary'
        },
        idioma: 'Language *',
        idiomas: {
          p: 'Portuguese',
          i: 'English',
          e: 'Spanish',
          o: 'Other'
        },
        tags: 'Tags *',
        cc: 'Creative Commons license *',
        comercial: {
            pergunta: 'Allow commercial uses of your work?',
            s: 'Yes',
            n: 'No'
        },
        modificacao: {
            pergunta: 'Allow adaptations of your work?',
            s: 'Yes',
            i: 'Yes, as long as others share alike'
        },
        validacao: {
          titulo: 'Please, enter the OER title.',
          descricao: 'Please, describe the OER.',
          autores: 'Please, enter the name of at least one author.',
          tipo: 'Please, enter the OER type.',
          area: 'Please, enter the area of knowledge of the OER.',
          idioma: 'Please, enter the OER language.',
          tags: 'Please, enter at least one tag.',
          comercial: 'Please, decide on the commercial use of the OER.',
          modificacao: 'Please, decide on the adaptations of the OER.',
          editor: 'Please, you are supposed to enter some content for the OER.'
        },
      },
      //REVISÃO + DISPLAY
      revisao: {
        capa: 'Set the cover image',
        autores: 'Author(s)',
        tipo: 'Content type',
        area: 'Area of knowledge',
        idioma: 'Language',
        data: 'Publication date',
        tags: 'Tags',
        licenca: 'License',
        arquivo: 'Filename',
        reaurl: 'Link to share this OER',
        reaurlclick: 'Click to copy the link address'
      },
      //AVALIAÇÃO
      avaliacao: {
        pergunta: 'What did you think of this OER?',
        nota: 'Your rating',
        comentario: 'Register you comment here'
      },
      //CAIXAS DE DIÁLOGO
      sucesso: {
        titulo: 'Congratulations!',
        mensagem: 'You have just published an OER and contributed to a quality education!',
        titulodraft: 'Well done!',
        mensagemdraft: 'You have just saved an OER draft and you can continue editing it at any time!',
        titulocolaborador: 'Welcome!',
        mensagememail: 'Please, access your e-mail to activate your registration. Thank you!',
        tituloatualizacao: 'Profile update',
        mensagematualizacao: 'Your data were updated!'
      },
      download: {
        titulo: 'Download progress',
        progresso: 'Please, wait for the download. In the meantime, do not refresh or close this page.'
      },
      aviso: {
        titulo: 'Warning'
      },
      social: {
        facebook: 'Share on Facebook',
        linkedin: 'Share on LinkedIn',
        gmail: 'Share via Gmail',
        whatsapp: 'Share via WhatsApp',
        Telegram: 'Share via Telegram',
        hashtag: '#OpenEducationalResource',
        message: 'Hello! Check this open educational resource: \n'
      }
    },
  },
})

export default i18n

// Using a { localeCode: localeData } structure
// allows us to add metadata, like a name, to each
// locale as our needs grow.
export const supportedLocales = {
    'pt-br': { name: 'Português' },
    'en-us': { name: 'English' }
  }