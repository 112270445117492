<template>
    <div class="container">
        <header class="d-flex flex-wrap justify-content-center py-3 mb-4">
            <span class="d-flex align-items-center mb-3 mb-md-0 me-lg-auto text-dark text-decoration-none">
                <router-link to="/">
                    <img :src="logo" :alt="alt" id="logo" width="130" style="position: relative; top: -5px;">               
                </router-link>
            </span>

            <ul class="nav nav-pills">
                <li class="nav-item">
                    <a href="#" @click="onLocaleChange('pt-br')" class="nav-link px-0">
                        <img src="/img/icons8-brazil-100.png" width="20" style="position: relative; top: -1px;" alt="Português">
                    </a>
                </li>
                <li class="nav-item">
                    <a href="#" @click="onLocaleChange('en-us')" class="nav-link ps-1 pe-5">
                        <img src="/img/icons8-usa-100.png" width="20" style="position: relative; top: -1px;" alt="English">
                    </a>
                </li>
                <li class="nav-item" v-show="userStore.isLogged"><router-link to="/novo" class="nav-link active me-3" aria-current="page">{{ $t('navbar.nr') }}</router-link></li>
                <li class="nav-item"><router-link to="/educacaoaberta" class="nav-link">{{ $t('navbar.ea') }}</router-link></li>
                <!-- <li class="nav-item"><a href="#" class="nav-link">Ajuda</a></li> -->
                <li class="nav-item"><router-link to="/contato" class="nav-link">{{ $t('navbar.contato') }}</router-link></li>
                <li class="nav-item"><router-link to="/sobre" class="nav-link">{{ $t('navbar.sobre') }}</router-link></li>

                <li class="nav-item" v-show="! userStore.isLogged">
                    <router-link to="/login" class="nav-link">
                        <img src="/img/icons8-enter-96.png" width="20" style="position: relative; top: -1px;">{{ $t('navbar.entrar') }}
                    </router-link>
                </li>

                <li class="dropdown text-end ms-3" v-show="userStore.isLogged">
                    <a href="#" :title="userStore.nome" class="d-block link-dark text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                        <img :src="userIcon" alt="user" width="32" height="32" class="rounded-circle" style="position: relative; top: 3px;">
                    </a>
                    <ul class="dropdown-menu text-small" aria-labelledby="dropdownUser1">
                        <li><a class="dropdown-item text-primary" href="#"></a></li>
                        <li><router-link to="/novo" class="dropdown-item">{{ $t('navbar.user.nr') }}</router-link></li>
                        <li><router-link to="/meus" class="dropdown-item">{{ $t('navbar.user.mr') }}</router-link></li>
                        <li><a class="dropdown-item" href="#" th:text="#{drop.configuracoes}">{{ $t('navbar.user.config') }}</a></li>
                        <li><router-link to="/colaborador" class="dropdown-item">{{ $t('navbar.user.perfil') }}</router-link></li>
                        <li><hr class="dropdown-divider"></li>
                        <li><a class="dropdown-item" @click="$emit('logout')">{{ $t('navbar.user.sair') }}</a></li>
                    </ul>
                </li>
            </ul>        
        </header>
    </div>
</template>

<script>
import { useUserStore } from '@/utils/userStore';

export default {
    name: "NavBar",
    props: ['logo', 'alt', 'userIcon', 'loggedUser', 'username', 'id'],
    emits: ['logout'],
    methods: {
        onLocaleChange(locale) {
            // If the selected locale is the same as the active one, do nothing
            if (locale === this.$i18n.locale) {
                return
            }
            // Navigate to the localized root route for the chosen locale
            this.$i18n.locale = locale;
        },
    },
    setup() {
        const userStore = useUserStore();

        return { userStore };
    }
}
</script>