import { createApp } from 'vue'
import { createPinia } from 'pinia'
import WaveUI from 'wave-ui'
import 'wave-ui/dist/wave-ui.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import App from './App.vue'
import router from './router'
import VueHtmlToPaper from './utils/VueHtmlToPaper'
import i18n from './i18n'
import VueAwesomePaginate from "vue-awesome-paginate";
import "vue-awesome-paginate/dist/style.css";

const pinia = createPinia()
const app = createApp(App)

app.config.globalProperties.$serverDomain = 'https://aquarelaapi.dev.br'
//app.config.globalProperties.$serverDomain = 'http://localhost:8081'

app.use(router).use(pinia).use(WaveUI).use(VueHtmlToPaper).use(i18n).use(VueAwesomePaginate).mount('#app')

import "bootstrap/dist/js/bootstrap.js"