import { defineStore } from "pinia";

export const useUserStore = defineStore("user", {
  state: () => {
    return { 
      id: 0,
      email: '',
      nome: '',
      token: ''
     };
  },
  actions: {
    initialize() {
      let userData = localStorage.getItem('loggedUser');

      if (userData != null)
        this.$state = JSON.parse(userData);
    },
    login(id, email, nome, token) {
      this.id = id;
      this.email = email;
      this.nome = nome;
      this.token = token;

      localStorage.setItem('loggedUser', JSON.stringify(this.$state));
    },
    logout() {
      localStorage.removeItem('loggedUser');
      this.reset();
    },
    reset() {
      this.id = 0;
      this.email = '';
      this.nome = '';
      this.token = '';
    }
  },
  getters: {
    getId: (state) => {
      return state.id;
    },
    getEmail: (state) => {
      return state.email;
    },
    getNome: (state) => {
      return state.nome;
    },
    getToken: (state) => {
      return state.token;
    },
    isLogged: (state) => {
      return state.id > 0 ? true : false;
    }
  },
});