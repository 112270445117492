import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'search',
    component: () => import(/* webpackChunkName: "search" */ '../views/SearchView.vue'),
    meta: {
      title: 'aquare!a',
      metaTags: [
       {
         name: 'description',
         content: "Repositório dinâmico de REA"
       },
       {
        name: 'keywords',
        content: "REA, OER, Educação Básica, Primary and secondary education"
       }
      ]
    }
  },
  {
    path: '/display',
    name: 'display',
    component: () => import(/* webpackChunkName: "display" */ '../views/OerFullDisplayView.vue')
  },
  {
    path: '/display/:id',
    name: 'displaytrilha',
    component: () => import(/* webpackChunkName: "displaytrilha" */ '../views/OerFullDisplayView.vue')
  },
  //rota específica para compartilhar um REA em redes sociais
  {
    path: '/rea/:id',
    name: 'reaurl'
  },
  {
    path: '/sobre',
    name: 'sobre',
    component: () => import(/* webpackChunkName: "sobre" */ '../views/AboutView.vue')
  },
  {
    path: '/educacaoaberta',
    name: 'educacaoaberta',
    component: () => import(/* webpackChunkName: "educacaoaberta" */ '../views/OpenEducationView.vue')
  },
  {
    path: '/contato',
    name: 'contato',
    component: () => import(/* webpackChunkName: "educacaoaberta" */ '../views/EmailView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
  },
  {
    path: '/colaborador',
    name: 'colaborador',
    component: () => import(/* webpackChunkName: "colaborador" */ '../views/ColaboradorView.vue')
  },
  {
    path: '/novo',
    name: 'novo',
    component: () => import(/* webpackChunkName: "novo" */ '../views/OerOptionsView.vue')
  },
  {
    path: '/documento',
    name: 'documento',
    component: () => import(/* webpackChunkName: "documento" */ '../views/OerCreationView.vue')
  },
  {
    path: '/trilha',
    name: 'trilha',
    component: () => import(/* webpackChunkName: "trilha" */ '../views/OerCreationView.vue'),
    children: [
      {
        path: '/trilha/search',
        name: 'modalsearch',
        components: { modal: () => import(/* webpackChunkName: "modalsearch" */ '../views/SearchView.vue') }
      },
      {
        path: '/trilha/display',
        name: 'modaldisplay',
        components: { modal: () => import(/* webpackChunkName: "modaldisplay" */ '../views/OerFullDisplayView.vue') }
      },
      {
        path: '/trilha/display/:id',
        name: 'modaldisplaytrilha',
        components: { modal: () => import(/* webpackChunkName: "modaldisplaytrilha" */ '../views/OerFullDisplayView.vue') }
      }
    ]
  },
  {
    path: '/arquivo',
    name: 'arquivo',
    component: () => import(/* webpackChunkName: "arquivo" */ '../views/OerCreationView.vue')
  },
  {
    path: '/meus',
    name: 'meus',
    component: () => import(/* webpackChunkName: "search" */ '../views/MyOerView.vue')
  },
  {
    path: '/meus/display',
    name: 'meusdisplay',
    component: () => import(/* webpackChunkName: "meusdisplay" */ '../views/OerFullDisplayView.vue')
  },
  {
    path: '/meus/display/:id',
    name: 'meusdisplaytrilha',
    component: () => import(/* webpackChunkName: "meusdisplaytrilha" */ '../views/OerFullDisplayView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router