import { defineStore } from "pinia";

/* uso do Localbase como substituição ao localStorage */
import Localbase from 'localbase'
let db = new Localbase('db')
db.config.debug = false

export const useDraftStore = defineStore("draft", {
  state: () => {
    return { 
      data: {
        dtype: '',
        id: '',
        titulo: '',
        descricao: '',
        autores: [],
        tipoConteudo: '',
        area: '',
        idioma: '',
        tags: [],
        usoComercial: '',
        modificacao: '',
        licenca: '',
        licencaDraft: '', /* campo temporário, usado para controle de licença de novos REA do tipo Trilha de Aprendizagem */
        conteudo: '',
        trilha: [], 
        url: '',
        arquivo: {},
        capa: '',
        status: ''
      },
      revise: false, /* indica se é adaptação */
      update: false  /* indica se é edição de um rascunho */
     };
  },
  actions: {
    initialize(id) {
      let draftOer = localStorage.getItem('draftOer' + id);

      if (draftOer != null) {
        //this.$state = JSON.parse(draftOer);
        /* essa alteração foi realizada com o objetivo de evitar a perda dos conteúdos por causa do tempo de espera
           de retorno da Promise do localbase */
        let dados = JSON.parse(draftOer);

        this.data.dtype = dados.data.dtype;
        this.data.id = dados.data.id;
        this.data.titulo = dados.data.titulo;
        this.data.descricao = dados.data.descricao;
        this.data.autores = dados.data.autores;
        this.data.tipoConteudo = dados.data.tipoConteudo;
        this.data.area = dados.data.area;
        this.data.idioma = dados.data.idioma;
        this.data.tags = dados.data.tags;
        this.data.usoComercial = dados.data.usoComercial;
        this.data.modificacao = dados.data.modificacao;
        this.data.licenca = dados.data.licenca;
        this.data.licencaDraft = dados.data.licencaDraft;
        this.data.capa = dados.data.capa;
        this.data.status = dados.data.status;
        this.revise = dados.revise;
        this.update = dados.update;

        this.initializeLocalbase(id);
      }
      else {
        /* cria um documento para o caso da execução do métod 'setData(id)' na criação de um novo REA */   
        db.collection('draftOer' + id).set([
          {
            id: 1,
            conteudo: '',
            trilha: [],
            url: '',
            arquivo: {}
          }
        ]);
      }
    },
    initializeLocalbase(id) {
      db.collection('draftOer' + id).doc({ id: 1 }).get().then(document => {
        if (document !== undefined) {
          this.data.conteudo = document.conteudo;
          this.data.trilha = document.trilha.length > 0 ? JSON.parse(document.trilha) : [];
          this.data.url = document.url;
          this.data.arquivo = document.arquivo.length > 0 ? JSON.parse(document.arquivo) : {};
        }
      });
    },
    setDtype(dtype) {
      this.data.dtype = dtype;
    },
    setData(id) {
      /* grava os dados no localstorage, desconsiderando os campos referentes a conteudo, trilha e arquivo */
      let dados = {
        data: {
          dtype: this.data.dtype,
          id: this.data.id,
          titulo: this.data.titulo,
          descricao: this.data.descricao,
          autores: this.data.autores,
          tipoConteudo: this.data.tipoConteudo,
          area: this.data.area,
          idioma: this.data.idioma,
          tags: this.data.tags,
          usoComercial: this.data.usoComercial,
          modificacao: this.data.modificacao,
          licenca: this.data.licenca,
          licencaDraft: this.data.licencaDraft,
          conteudo: '',
          trilha: [], 
          url: '',
          arquivo: {},
          capa: this.data.capa,
          status: this.data.status
        },
        revise: this.revise,
        update: this.update
      }

      localStorage.setItem('draftOer' + id, JSON.stringify(dados));
    },
    setDataLocalbase(id) {
      /* grava os dados no localbase */   
      let dados = {
        id: 1,
        conteudo: this.data.conteudo === undefined ? '' : this.data.conteudo,
        trilha: this.data.trilha === undefined ? [] : JSON.stringify(this.data.trilha),
        url: this.data.url === undefined ? '' : this.data.url,
        arquivo: this.data.arquivo === undefined ? {} : JSON.stringify(this.data.arquivo)
      }

      db.collection('draftOer' + id).set(new Array(dados));
    },
    setRevise(flag) {
      this.revise = flag;
    },
    setUpdate(flag) {
      this.update = flag;
    },
    reset(id) {
      this.data.dtype = '';
      this.data.id = '';
      this.data.titulo = '';
      this.data.descricao = '';
      this.data.autores = [];
      this.data.tipoConteudo = '';
      this.data.area = '';
      this.data.idioma = '';
      this.data.tags = [];
      this.data.usoComercial = '';
      this.data.modificacao = '';
      this.data.licenca = '';
      this.data.licencaDraft = '';
      this.data.conteudo = '';
      this.data.trilha = []; 
      this.data.url = '';
      this.data.arquivo = {};
      this.data.capa = '';
      this.data.status ='';

      this.revise = false;
      this.update = false;

      localStorage.removeItem('draftOer' + id);
      //db.collection('draftOer' + id).delete();
      db.collection('draftOer' + id).doc({ id: 1 }).delete();
    }
  },
  getters: {
    isDocumento: (state) => {
      return state.data.dtype == 'D' ? true : false;
    },
    isTrilha: (state) => {
      return state.data.dtype == 'T' ? true : false;
    },
    isArquivo: (state) => {
      return state.data.dtype == 'A' ? true : false;
    },
    isRevise: (state) => {
      return state.revise;
    },
    isUpdate: (state) => {
      return state.update;
    },
    hasData: (state) => {
      return state.data.dtype.length > 0 ? true : false;
    }
  },
});