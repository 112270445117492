<template>
  <NavBar :logo="appLogo" :alt="appName" :userIcon="userIcon" @logout="logout" />
  
  <router-view v-slot="{ Component }">
    <keep-alive include="SearchView,MyOerView">
      <component :is="Component" />
    </keep-alive>
  </router-view>

  <!-- diálogo para avisar colaborador de que é necessário continuar edição de REA não salvo -->
  <w-dialog v-model="warning.show" title="Aviso" persistent :width="550">
    Há um REA em edição com dados que ainda não foram salvos.<br>
    Por favor, salve pelo menos o rascunho ou cancele a edição.
    <template #actions>
      <div class="spacer" />
      <w-button class="mr2" @click="warning.show = false,continueDraft()" bg-color="error">
        OK
      </w-button>
    </template>
  </w-dialog>

  <w-dialog v-model="progress.show" persistent :width="300">
      <div class="row justify-content-md-center">
        <w-progress class="ma1" circle size="7em"></w-progress>
      </div>
      <p>{{ $t('aguardar') }}</p>
    </w-dialog>
</template>

<script>
import NavBar from './components/NavBar.vue';
import useEventsBus from './utils/eventBus';
import { useUserStore } from './utils/userStore';
import { useDraftStore } from './utils/draftStore';
//import { Buffer } from 'buffer';
import axios from 'axios';
import { useReaStore } from '@/utils/reaStore';

const { emit } = useEventsBus();

export default {
  metaInfo: {
      title: 'aquare!a',
      titleTemplate: 'aquare!a'
  },
  components: {
    NavBar
  },
  data() {
    return {
      appLogo: "/img/logo.png",
      appName: "aquarela",
      userIcon: "/img/icons8-user-100.png",

      warning: { show: false },
      progress: { show: false }
    }
  },
  watch: {
    $route (to, from) {
      if (from.name == 'login')
        this.checkDraft();

      if (to.name === 'reaurl')
        this.displayRea(to.params.id);

      if (this.$route.name != 'login' &&
          this.$route.name != 'display' &&
          this.$route.name != 'displaytrilha' &&
          this.$route.name != 'reaurl' &&
          this.$route.name != 'search' &&
          this.$route.name != 'educacaoaberta' && 
          this.$route.name != 'sobre' && 
          this.$route.name != 'contato' &&
          this.$route.name != 'colaborador'
        )
        if (! this.userStore.isLogged)
          window.addEventListener("beforeunload", this.logout());

      // if (this.userStore.isLogged) {
      //   var base64Payload = this.userStore.token.split(".")[1];
      //   var payloadBuffer = Buffer.from(base64Payload, "base64");
      //   let token = JSON.parse(payloadBuffer.toString());
      //   console.log(parseInt(token.exp));
      //   console.log(new Date(parseInt(token.exp) * 1000))
      // };
    }
  },
  methods: {
    logout() {
      if (! this.draftStore.hasData)
        this.userStore.logout();

        this.redirect();
    },
    redirect() {
      this.$router.push('/');

      emit('logout'); /* triggers a watch in SearchView view */
    },
    checkDraft() {
      this.draftStore.initialize(this.userStore.id);
      
      if (this.userStore.isLogged && this.draftStore.hasData)
        this.warning.show = true;
    },
    continueDraft() {
      if (this.draftStore.isDocumento)
        this.$router.push('/documento');
      else
      if (this.draftStore.isArquivo)
        this.$router.push('/arquivo');
      else
      if (this.draftStore.isTrilha)
        this.$router.push('/trilha');
    },

    //*** método para exibir um REA cujo link foi compartilhado via redes sociais ***
    displayRea(id) {
        let url = this.$serverDomain + '/api/reas/' + id;

        this.progress.show = true;

        try {
            axios
                .get(url)
                .then((response) => {
                    this.rea = response.data;
                    this.reaStore.setData(this.rea);

                    /* a página de busca pode ser usada no modo tradicional ou no modal durante a criação de uma trilha */
                    this.$router.push('/display');

                    this.progress.show = false;
                });
        } catch (error) {
            this.progress.show = false;
            console.log(error);
        }
    }
  },
  setup() {
    const userStore = useUserStore();

    const draftStore = useDraftStore();

    const reaStore = useReaStore();

    return { userStore, draftStore, reaStore };
  },
  mounted() {
    document.title = "aquare!a";

    this.userStore.initialize();

    //caso o visitante tente acessar uma url que exige login, a página principal é recarregada
    // if (window.performance.getEntriesByType("navigation")[0].type != 'reload' && ! this.userStore.isLogged)
    //   window.addEventListener("beforeunload", this.logout());

    this.checkDraft();
  }
}
</script>